import React, { useState } from "react";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import pageStyle from "../../../styles/pages/paymentDetails.module.css";
import pagesStyle from "../../../styles/page.module.css";
import FormattedMsg from "../../../locale/components/formatted-msg";
import Divider from "../../../components/divider";
import { Checkbox } from "../../../components/checkbox";
import Button from "../../../components/button";
import { useContext } from "react";
import LocaleContext from "../../../contexts/locale-context";
import { NewSubscriptionPage } from "../../common/newSubscription/newSubscriptionPage";
import cardStyle from "../../../styles/card.module.css";
import Card from "../../../components/card";
import IbanInput from "../../../components/iban-input";
import Chevron from "../../../components/chevron";
import { ThemeContext } from "../../../contexts/theme-context";
import { OverlayContext } from "../../../contexts/overlay-context";
import ConfigContext from "../../../contexts/config-context";
import useNetwork from "../../../components/network/use-network";
import { subscribe } from "../../../components/network/subscriber";
import { ErrorPage } from "./errorPage";
import { CallHandler } from "../../../components/call-handler";
import InPageLoadingSpinner from "../../../components/in-page-loading-spinner";
import ConfigHelper from "../../../config/config-helper";
import ValidatedInputText from "../../../components/input-validators/validated-input-text";

import style from "../../../styles/form.module.css";
import Helpers from "../../../components/helpers";
import createIbanValidator from "../../../components/input-validators/iban-validator";
import { useEffect } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { createAccountHolderValidator } from "../../../components/input-validators/account-holder-validator";

export const subscribeTag = "subscribe";

export default function PaymentDetails() {
  const navigate = useNavigate();
  const { formatMessage } = useContext(LocaleContext);
  const { showOverlay } = useContext(OverlayContext);
  const { config } = useContext(ConfigContext);
  const { checkNetworkCall, startNetworkCall, removeNetworkCall } =
    useNetwork();
  const accountHolderInputRef = useRef();

  const {
    formData: outletFormData,
    setData,
    setPageData,
    pages,
  } = useOutletContext();
  const { selectPlan, paymentDetails: data } = outletFormData;
  const plan = selectPlan?.plan;

  const [, forceUpdate] = useState(Date.now());

  const fullName = useMemo(
    () =>
      outletFormData.personalInformation.firstName +
      " " +
      outletFormData.personalInformation.lastName,
    [outletFormData]
  );
  const [formData, setFormData] = useState({
    iban: data?.iban || {
      value: "",
      exists: true,
      invalidIBANs: [],
      version: 0,
    },
    accountHolder: data?.accountHolder || fullName,
    agreed: data?.agreed || false,
  });

  useEffect(() => {
    if (!data.accountHolder || data.accountHolder === "") {
      setData("accountHolder", fullName, false);
    }
  }, [data, fullName, setData]);
  const [pdfError, setPdfError] = useState(null);

  const { theme } = useContext(ThemeContext);

  const validators = useRef({
    accountHolder: createAccountHolderValidator(fullName),
    iban: createIbanValidator(
      Helpers.getSavedCountry(true),
      "IBAN_invalid_characters_error_message",
      "IBAN_not_recognized",
      "IBAN_does_not_exist_error_message",
      formData.iban.invalidIBANs
    ),
  }).current;

  const minLengthAccountHolder = 6;
  const maxLengthAccountHolder = 32;
  const requiredAccountHolder = true;

  const [submittable, setSubmittable] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const isSubmittable =
      validators.iban.isSubmittable(formData.iban.value) &&
      validators.accountHolder.isSubmittable(
        formData.accountHolder,
        minLengthAccountHolder,
        maxLengthAccountHolder,
        requiredAccountHolder
      ) &&
      formData.accountHolder === fullName &&
      formData.agreed;

    const hasErrors = ["iban", "accountHolder"].some((field) => {
      const value = field === "iban" ? formData.iban.value : formData[field];
      if (!value || value.trim() === "") {
        return false;
      }
      if (field === "iban") {
        return !validators.iban.isSubmittable(value);
      } else if (field === "accountHolder") {
        return !validators.accountHolder.isSubmittable(
          value,
          minLengthAccountHolder,
          maxLengthAccountHolder,
          requiredAccountHolder
        );
      }
      return false;
    });

    if (isSubmittable !== submittable) {
      setSubmittable(isSubmittable);
      setData("submittable", isSubmittable, false);
    }

    if (hasErrors !== hasError) {
      setHasError(hasErrors);
      setData("hasError", hasErrors, false);
    }

    if (!hasErrors && hasError) {
      setHasError(false);
      setData("hasError", false, false);
    }
  }, [
    validators,
    formData.iban.value,
    formData.iban.version,
    formData.accountHolder,
    formData.agreed,
    formData,
    setData,
    submittable,
    hasError,
    fullName,
    minLengthAccountHolder,
    maxLengthAccountHolder,
    requiredAccountHolder,
  ]);

  const handleBackClick = () => {
    if (hasError) {
      showOverlay("discard-changes", {
        onMainAction: () => {
          setFormData((prev) => {
            let newFormData = { ...prev };
            ["iban", "accountHolder"].forEach((field) => {
              const value = newFormData[field]?.value || newFormData[field];
              if (value) {
                if (field === "iban" && !validators.iban.isSubmittable(value)) {
                  newFormData[field] = { value: "" };
                } else if (
                  field === "accountHolder" &&
                  !validators.accountHolder.isSubmittable(
                    value,
                    minLengthAccountHolder,
                    maxLengthAccountHolder,
                    requiredAccountHolder
                  )
                ) {
                  newFormData[field] = "";
                }
              }
            });
            return newFormData;
          });
          setPageData(false);
          showOverlay();
          navigate(pages.NewSubscription.CreatePassword.full);
        },
      });
    } else {
      setPageData();
      navigate(pages.NewSubscription.CreatePassword.full);
    }
  };

  if (!plan) {
    return <Navigate to={pages.NewSubscription.SelectPlan.full} />;
  }
  const subscribeCall = checkNetworkCall(subscribeTag, true, false);
  const startSubscribe = () => {
    forceUpdate(Date.now());
    //start Call
    return startNetworkCall(
      subscribeTag,
      () => subscribe(config, outletFormData),
      true,
      false
    );
  };

  const subscribeCallback = (result) => {
    setTimeout(() => {
      if (!(result instanceof Error)) {
        navigate(pages.NewSubscription.Result.full, {
          state: { status: "success" },
        });
      } else forceUpdate(Date.now());
    });
    return true;
  };

  const handleInput = (field, value) => {
    setFormData((prev) => {
      let res = { ...prev };

      if (field === "iban") {
        res[field] = {
          ...value,
          version: prev[field]?.version + 1,
        };
        if (validators.iban.isSubmittable(value.value)) {
          setHasError(false);
          setData("hasError", false, false);
        }
      } else {
        res[field] = value;
      }

      return res;
    });

    setData(field, value);
  };

  if (subscribeCall?.hasError()) {
    const error = subscribeCall.read();
    try {
      error.body.reason = JSON.parse(error.body.reason);
    } catch {}
    if (error.body?.reason?.errorCode?.[0].value === "32") {
      const errorIDs = {
        titleId: "IBAN_does_not_exist_error_page_title",
        descriptionId: "IBAN_does_not_exist_error_page_copy",
        descriptionValues: { IBAN: formData.iban.value },
        mainButtonId: "Change_IBAN_button",
        primaryAction: () => {
          const { value, invalidIBANs } = formData.iban;
          invalidIBANs.push(value);
          const data = {
            value,
            exists: false,
            invalidIBANs,
          };
          handleInput("iban", data);
          removeNetworkCall(subscribeTag, true);
        },
        hidePrimaryButton: false,
        hideSecondaryButton: true,
        descriptionidValue: { IBAN: formData.iban.value },
        heroImage: "invalidIban",
      };

      return <ErrorPage {...errorIDs} />;
    } else {
      const errorIDs = {
        titleId: "Subscription_failed_error_title",
        descriptionId: "Subscription_failed_error_copy",
        mainButtonId: "Try_to_send_OTP_again_button",
        secondaryActionId: "Quit_setup_button",
        primaryAction: startSubscribe,
        hidePrimaryButton: false,
      };
      return <ErrorPage {...errorIDs} />;
    }
  }

  if (pdfError) {
    const errorIDs = {
      titleId: "Loading_contract_summary_failed_error_page_headline",
      descriptionId: "Loading_contract_summary_failed_error_page_copy",
      secondaryActionId: "Quit_setup_button",
      hidePrimaryButton: true,
    };
    return <ErrorPage {...errorIDs} />;
  }

  return (
    <NewSubscriptionPage
      heroTitle={{ textId: "Payment_details_hero_title" }}
      plan={plan}
      noCard={true}
      spinnerMessageId="Waiting_for_Subscribing_copy"
    >
      {subscribeCall?.isPending() ? (
        <CallHandler call={subscribeCall} onFinish={subscribeCallback}>
          <InPageLoadingSpinner spinnerMessageId="Waiting_for_Subscribing_copy" />
        </CallHandler>
      ) : (
        <>
          <Card className={`${style.cardContainer} ${pageStyle.cardMeasures}`}>
            <h1 className={style.title}>
              <FormattedMsg id="Payment_details_page_title" />
            </h1>
            <p className={pageStyle.description}>
              {formatMessage("Payment_details_page_copy")}
            </p>
            <div className={style.row} data-testid="payment-details-form">
              <IbanInput
                value={formData.iban.value}
                focusedHint={formatMessage("IBAN_input_hint")}
                onChange={(e) =>
                  handleInput("iban", {
                    value: e.target.value,
                    exists: validators.iban.exists(formData.iban.value),
                    invalidIBANs: formData.iban.invalidIBANs,
                  })
                }
                required={true}
                validator={validators.iban}
              />
            </div>
            <div className={`${style.row}`}>
              <ValidatedInputText
                ref={accountHolderInputRef}
                data-testid="account-holder-input"
                value={formData.accountHolder}
                label={formatMessage("Account_holder_name_input_label") + "*"}
                focusedHint={formatMessage("Account_holder_name_hint")}
                onChange={(e) => handleInput("accountHolder", e.target.value)}
                onBlur={() => {
                  if (
                    formData.accountHolder !== fullName &&
                    validators.accountHolder.isSubmittable(
                      formData.accountHolder,
                      minLengthAccountHolder,
                      maxLengthAccountHolder,
                      requiredAccountHolder
                    )
                  ) {
                    validators.accountHolder.data.overlay = true;
                    showOverlay("yes-no-alert", {
                      titleId: "Save_changes_account_holder_Dialog_Headline",
                      descriptionId: "Save_changes_account_holder_Dialog_Copy",
                      yesButtonId: "Save_changes_button",
                      noButtonId: "Cancel_button",
                      onYes: () => {
                        // ASSUMPTION: the last word is the last name, all other words are the names
                        const names = formData.accountHolder.split(" ");
                        const { length } = names;

                        outletFormData.personalInformation.firstName = names
                          .slice(0, length - 1)
                          .join(" ");

                        outletFormData.personalInformation.lastName =
                          names[length - 1];

                        validators.accountHolder.data.fullName =
                          formData.accountHolder;

                        setData("accountHolder", formData.accountHolder, false);
                        setData("accountHolder", undefined);
                        showOverlay();
                        validators.accountHolder.data.overlay = false;
                      },
                      onNo: (_, viaIcon) => {
                        if (!viaIcon) {
                          setFormData((prev) => ({
                            ...prev,
                            accountHolder: fullName,
                          }));
                          setData("accountHolder", undefined);
                        } else accountHolderInputRef.current.focus();

                        showOverlay();
                        validators.accountHolder.data.overlay = false;
                      },
                      onClose: (e) =>
                        (validators.accountHolder.data.overlay = false),
                    });
                  }
                }}
                minLength={minLengthAccountHolder}
                maxLength={maxLengthAccountHolder}
                xIndicatorVariant={theme === "dark" ? "white" : "grey"}
                validator={validators.accountHolder}
                required={requiredAccountHolder}
              />
            </div>
            <div className={`${style.row}`}>
              <Checkbox
                data-testid="sepa-direct-debit-checkbox"
                checked={formData.agreed}
                id="sepa-direct-debit"
                onChange={(e) => handleInput("agreed", e.target.checked)}
              >
                <div className={style.info}>
                  <p>
                    <FormattedMsg
                      id="SEPA_Direct_agreement_checkbox_copy"
                      values={{
                        overlay: (
                          <span
                            className={pagesStyle.link}
                            data-testid="sepa-direct-debit-overlay"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              showOverlay("info-overlay", {
                                title: (
                                  <FormattedMsg id="SEPA_Direct_Debit_overlay_title" />
                                ),
                                text: (
                                  <FormattedMsg id="SEPA_Direct_Debit_overlay_copy" />
                                ),
                              });
                            }}
                          >
                            <FormattedMsg id="SEPA_Direct_Debit_overlay_title" />
                          </span>
                        ),
                      }}
                    />
                  </p>
                </div>
              </Checkbox>
            </div>
          </Card>
          <Card
            className={`${cardStyle.cardBanner}`}
            data-testid="contract-summary-overlay"
            onClick={() => {
              showOverlay("pdf-overlay", {
                name: `Vodafone-${plan.commercialName.replace(
                  " ",
                  "-"
                )}-${plan.durationLabel.replace(" ", "-")}.pdf`,
                downloadable: true,
                src:
                  config.network.endpoints.contractSummary.url +
                  "?planid=" +
                  plan.pricePlanId,
                callOptions: {
                  type: "json",
                  config: {
                    ...config.network.endpoints.contractSummary,
                    url:
                      config.network.endpoints.contractSummary.url +
                      "?planid=" +
                      plan.pricePlanId,
                  },
                  attempts: ConfigHelper.getContractSummaryAttempts(),
                  retryAfterSeconds:
                    ConfigHelper.getContractSummaryAttemptDelaySeconds(),
                  waitForMillis:
                    ConfigHelper.getContractSummaryAttemptDelaySeconds() * 1000,
                },
                mainButtonText: formatMessage(
                  "Download_pre-contract_summary_button"
                ),
                backButtonText: formatMessage("Back_to_payment_detail_button"),
                onBeforePDFLoad: async (res) => {
                  if (
                    res instanceof Error ||
                    res.status === 204 ||
                    !res.contractSummary
                  )
                    return res;
                  // Base64 conversion for solving font issues
                  // const raw = atob(res.contractSummary);
                  const url =
                    "data:application/pdf;base64," + res.contractSummary;
                  return {
                    data: { url },
                    downloadLink: url,
                  };
                },
                spinnerMessageIds: {
                  id: "Loading_contract_summary_copy",
                  changeIdAfterSeconds: 10,
                  takingTooLongId: "Long_Loading_contract_summary_copy",
                },
                onError: (error) => setPdfError(error),
              });
            }}
          >
            <p>
              <FormattedMsg id="Contract_summary_link_copy" />
            </p>
            <Chevron />
          </Card>
          <Card className={style.cardContainer}>
            <div className={pageStyle.row}>
              <h3 className={pageStyle.headerName}>{plan.commercialName}</h3>
              <p className={pageStyle.headerCopy}>
                <span className={pageStyle.headerCopyBold}>
                  {plan.pricing.replace("EUR", "€")}
                </span>
                {" /" + plan.recurrency}
              </p>
            </div>
            {plan.hasPromotion && (
              <div className={pageStyle.row}>
                <h3 className={pageStyle.headerName}>
                  <FormattedMsg id="Discount_row_title" />
                </h3>
                <p className={pageStyle.headerCopy}>
                  <span className={pageStyle.headerCopyBold}>
                    <FormattedMsg id="Discount_row_copy" />*
                  </span>
                </p>
              </div>
            )}
            {plan.promotionDetails && (
              <div className={pageStyle.row}>
                <p className={pageStyle.notes}>{plan.promotionDetails}</p>
              </div>
            )}
            <div className={pageStyle.row}>
              <Divider />
            </div>
            <div className={style.row}>
              <h4 className={pageStyle.sectionHeadline}>
                <FormattedMsg id="Terms_and_conditions_headline" />
              </h4>
            </div>
            <ul className={pageStyle.list}>
              <li>
                <div className={style.row}>
                  <p className={style.info}>
                    <FormattedMsg
                      id="Terms_and_conditions_copy_1"
                      values={{
                        Price_list_link: (
                          <Button
                            data-testid="price-list-link"
                            href="https://www.vodafone.de/agb.html"
                            className={pagesStyle.link}
                            variant="link"
                            external
                          >
                            <FormattedMsg id="Price_list_label" />
                          </Button>
                        ),
                        AGB_link: (
                          <Button
                            data-testid="agb-link"
                            href="https://www.vodafone.de/media/downloads/pdf/20031_2110_adt09_21_v_agb_vf_dienstleistungen_900271.pdf"
                            className={pagesStyle.link}
                            variant="link"
                            external
                          >
                            <FormattedMsg id="AGB_label" />
                          </Button>
                        ),
                      }}
                    />
                  </p>
                </div>
              </li>
              <li>
                <div className={style.row}>
                  <p className={style.info}>
                    <FormattedMsg
                      id="Terms_and_conditions_copy_2"
                      values={{
                        Withdrawal_link: (
                          <span
                            data-testid="widerrufsrecht-overlay"
                            className={pagesStyle.link}
                            onClick={(e) => {
                              e.preventDefault();
                              showOverlay("pdf-overlay", {
                                onBeforePDFLoad: async (blob) => ({
                                  data: { blob },
                                }),
                                src: "/static/Widerrufsrecht.pdf",
                                name: "Widerrufsrecht.pdf",
                                downloadable: false,
                                backButtonText: formatMessage(
                                  "Back_to_payment_detail_button"
                                ),
                                onClose: (error) => setPdfError(error),
                              });
                            }}
                          >
                            <FormattedMsg id="Withdrawal_label" />
                          </span>
                        ),
                      }}
                    />
                  </p>
                </div>
              </li>
            </ul>
            <div className={style.row}>
              <h4 className={pageStyle.sectionHeadline}>
                <FormattedMsg id="Credit_check_headline" />
              </h4>
            </div>
            <p className={pageStyle.info}>
              <FormattedMsg id="Credit_check_copy_1" />
            </p>
            <h4 className={pageStyle.sectionHeadline}>
              <FormattedMsg id="Data_processing_headline" />
            </h4>
            <p className={pageStyle.info}>
              <FormattedMsg
                id="Data_processing_copy_1"
                values={{
                  Vodafone_companies_overlay: (
                    <span
                      data-testid="vodafone-companies-overlay"
                      className={pagesStyle.link}
                      onClick={(e) => {
                        e.preventDefault();
                        showOverlay("info-overlay", {
                          title: (
                            <FormattedMsg id="Vodafone_companies_overlay_title" />
                          ),
                          text: (
                            <FormattedMsg id="Vodafone_companies_overlay_copy" />
                          ),
                        });
                      }}
                    >
                      <FormattedMsg id="Vodafone_companies_overlay_label" />
                    </span>
                  ),
                  Products_and_services_overlay: (
                    <span
                      data-testid="products-and-services-overlay"
                      className={pagesStyle.link}
                      onClick={(e) => {
                        e.preventDefault();
                        showOverlay("info-overlay", {
                          title: (
                            <FormattedMsg id="Products_and_services_overlay_title" />
                          ),
                          text: (
                            <FormattedMsg id="Products_and_services_overlay_copy" />
                          ),
                        });
                      }}
                    >
                      <FormattedMsg id="Products_and_services_overlay_label" />
                    </span>
                  ),
                  Contractual_data_overlay: (
                    <span
                      data-testid="contractual-data-overlay"
                      className={pagesStyle.link}
                      onClick={(e) => {
                        e.preventDefault();
                        showOverlay("info-overlay", {
                          title: (
                            <FormattedMsg id="Contractual_data_overlay_title" />
                          ),
                          text: (
                            <FormattedMsg id="Contractual_data_overlay_copy" />
                          ),
                        });
                      }}
                    >
                      <FormattedMsg id="Contractual_data_overlay_label" />
                    </span>
                  ),
                  Datenschutz_link: (
                    <Button
                      data-testid="datenschutz-link"
                      href="https://www.vodafone.de/unternehmen/soziale-verantwortung/datenschutz-privatsphaere.html"
                      className={pagesStyle.link}
                      variant="link"
                      external
                    >
                      <FormattedMsg id="Datenschutz_label" />
                    </Button>
                  ),
                }}
              />
            </p>
            <p className={pageStyle.disclaimer}>
              <FormattedMsg id="Data_processing_disclaimer" />
            </p>
            <h4 className={pageStyle.sectionHeadline}>
              <FormattedMsg id="Advertising_consent_headline" />
            </h4>
            <div className={pageStyle.info} data-testid="vodafone-adv-text">
              <p>
                <FormattedMsg id="Advertising_consent_legal_copy" />
              </p>
            </div>
            <div className={pageStyle.info}>
              <p className={pageStyle.info}>
                <FormattedMsg id="Advertising_objecting_consent_legal_copy" />
              </p>
            </div>
            <div className={pageStyle.footer}>
              <div className={style.row}>
                <Divider className={style.divider} />
              </div>
              <div className={style.buttonsContainer}>
                <Button
                  data-testid="back-button"
                  variant="tertiary"
                  onClick={handleBackClick}
                  data-link-id="back_button"
                >
                  <FormattedMsg id="Secondary_action" />
                </Button>
                <Button
                  data-testid="next-button"
                  data-link-id="next_button"
                  variant={submittable ? "primary" : "disabled"}
                  onClick={() => {
                    setPageData();
                    startSubscribe();
                  }}
                >
                  <FormattedMsg id="Subscribe_button" />
                </Button>
              </div>
            </div>
          </Card>
        </>
      )}
    </NewSubscriptionPage>
  );
}
